@import "../../mixins";

.modal-search {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;

  width: 100%;
  height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  background-color: var(--bg-white);
  border-radius: 0 0 var(--radius-common) var(--radius-common);
  opacity: 0;
  visibility: hidden;

  transition: opacity var(--animation-timing) var(--cubic-bezier),
    visibility var(--animation-timing) var(--cubic-bezier),
    height var(--animation-timing) var(--cubic-bezier);

  &_active {
    height: rem(361);

    opacity: 1;
    visibility: visible;

    @include mediaBigDesktop {
      height: big(361);
    }

    @include mediaLaptop {
      height: rem(315)
    }

    @include mediaMobile {
      height: rem(252);
    }

    & .modal-search {
      &__label {
        padding-bottom: rem(25);

        @include mediaBigDesktop {
          padding-bottom: big(25);
        }

        @include mediaMobile {
          padding-bottom: rem(15);
        }
      }
    }
  }

  &__close {
    position: absolute;
    top: rem(42);
    right: var(--container-offset);

    width: rem(24);
    height: rem(24);

    @include mediaBigDesktop {
      top: big(42);

      width: big(24);
      height: big(24);
    }

    @include mediaLaptop {
      top: rem(39);
    }

    @include mediaTablet {
      top: rem(23);

      width: rem(18);
      height: rem(18);
    }

    @include mediaMobile {
      top: rem(20);

      width: rem(16);
      height: rem(16);
    }

    @include hover {
      & .button {
        &__icon {
          fill: var(--accent-light-primary);
        }
      }
    }

    & .button {
      &__icon {
        fill: var(--accent-dark-primary);
      }
    }
  }

  &__form {
    width: 100%;
  }

  &__label {
    width: 100%;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    border-bottom: solid 1px var(--stroke-dark-secondary);

    transition: padding-bottom var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      border-bottom-width: big(1);
    }
  }

  & .text-input {
    width: 100%;

    &__input {
      margin: 0;

      padding: 0;
      width: 100%;

      font: var(--font-body-L);

      border: none;
    }
  }

  &__button {
    width: rem(24);
    height: rem(24);

    flex-shrink: 0;

    @include mediaBigDesktop {
      width: big(24);
      height: big(24);
    }

    @include mediaTablet {
      width: rem(18);
      height: rem(18);
    }

    @include mediaMobile {
      width: rem(16);
      height: rem(16);
    }

    @include hover {
      & .button {
        &__icon {
          fill: var(--accent-light-primary);
        }
      }
    }

    & .button {
      &__icon {
        width: 100%;
        height: 100%;

        fill: var(--accent-dark-primary);
      }
    }
  }
}
